export const ALKAI_APP_LOADED_EVENT = "AlkaiAppLoaded";
export const ALKAI_APP_UNLOADED_EVENT = "AlkaiAppUnloaded";
export const INITIAL_RUN_SHOWN_EVENT = "InitialRunShown";
export const SIGN_IN_CLICKED_EVENT = "SignInClicked";
export const SIGN_IN_AS_GUEST_CLICKED_EVENT = "SignInAsGuestClicked";
export const VERIFY_CLICKED_EVENT = "VerifyClicked";
export const CREATE_ACCOUNT_SHOWN_EVENT = "CreateAccountShown";

export const USER_SIGN_IN_SUCCEEDED = "UserSignInSucceeded";

export const GUEST_SIGN_IN_SUCCEEDED = "GuestSignInSucceeded";

export const WRONG_NUMBER_CLICKED_EVENT = "WrongNumberClicked";
export const WRONG_EMAIL_ADDRESS_CLICKED_EVENT = "WrongEmailAddressClicked";
export const RESEND_CODE_CLICKED_EVENT = "ResendCodeClicked";
export const ACCEPT_CONNECT_INSTAGRAM_CLICKED_EVENT = "AcceptConnectInstagramClicked";
export const REJECT_CONNECT_INSTAGRAM_CLICKED_EVENT = "RejectConnectInstagramClicked";
export const INSTAGRAM_CONNECTED_EVENT = "InstagramConnected";
export const ACCEPT_CONNECT_FACEBOOK_PAGE_CLICKED_EVENT = "AcceptConnectFacebookClicked";
export const FACEBOOK_PAGE_CONNECTED_EVENT = "FacebookPageConnected";
export const BUSINESS_NAME_REQUEST_SHOWN_EVENT = "BusinessNameRequestShown";
export const BUSINESS_NAME_COMPLETED_EVENT = "BusinessNameCompleted";
export const BUSINESS_DESCRIPTION_REQUEST_SHOWN_EVENT = "BusinessDescriptionRequestShown";
export const BUSINESS_DESCRIPTION_COMPLETED_EVENT = "BusinessDescriptionCompleted";
export const TOPIC_SELECTION_REQUEST_SHOWN_EVENT = "TopicSelectionRequestShown";
export const TOPIC_SELECTION_ADD_MY_OWN_CLICKED_EVENT = "TopicSelectionAddMyOwnClicked";
export const TOPIC_SELECTION_ADD_MY_OWN_ADDED_EVENT = "TopicSelectionAddMyOwnAdded";
export const TOPIC_SELECTION_COMPLETED_EVENT = "TopicSelectionCompleted";
export const PLAN_SHOWN_EVENT = "PlanShown";
export const POST_SUCCESS_DIALOG_SHOWN_EVENT = "PostSuccessDialogShown";
export const WEEKLY_POSTING_PLAN_REQUEST_SHOWN = "WeeklyPostingPlanRequestShown";
export const WEEKLY_POSTING_PLAN_REQUEST_COMPLETED = "WeeklyPostingPlanCompleted";
export const FIRST_POST_REQUEST_SHOWN = "FirstPostRequestShown";
export const FIRST_POST_PROPOSED_TOPIC_CLICKED = "FirstPostProposedTopicClicked";
export const FIRST_POST_USE_MY_OWN_CLICKED = "FirstPostUseMyOwnClicked";
export const FIRST_POST_DIFFERENT_TOPIC_CLICKED = "FirstPostDifferentTopicClicked";
export const FIRST_POST_PREVIEW_SHOWN = "FirstPostPreviewShown";
export const POST_PREVIEW_SHOWN = "PostPreviewShown";
export const FIRST_POST_PREVIEW_CLICKED = "FirstPostPreviewClicked";
export const POST_PREVIEW_CLICKED = "PostPreviewClicked";

export const EDIT_POST_PREVIEW_SHARE_CLICKED_EVENT = "EditPostShareClicked";
export const EDIT_POST_PREVIEW_SHOWN_EVENT = "EditPostPreviewShown";
export const EDIT_POST_CANCEL_CLICKED_EVENT = "EditPostCancelClicked";
export const EDIT_POST_PRESET_SWITCHED_EVENT = "EditPostPresetSwitched";
export const EDIT_POST_LAYOUT_SWITCHED_EVENT = "EditPostLayoutSwitched";
export const EDIT_POST_HOLIDAY_LAYOUT_SWITCHED_EVENT = "EditPostHolidayLayoutSwitched";
export const EDIT_POST_COLOR_PALETTE_SWITCHED_EVENT = "EditPostColorPaletteSwitched";
export const EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_CUSTOM = "custom";
export const EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_DEFAULT = "default";
export const EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_WEBSITE_GENERATED = "website_generated";
export const EDIT_POST_COLOR_PALETTE_MY_WEBSITE_PREFIX = "My website";
export const EDIT_POST_FONT_SET_SWITCHED_EVENT = "EditPostFontSetSwitched";
export const EDIT_POST_LAYOUT_MENU_CLICKED_EVENT = "EditPostLayoutMenuClicked";
export const EDIT_POST_HOLIDAY_LAYOUT_MENU_CLICKED_EVENT = "EditPostHolidayLayoutMenuClicked";
export const EDIT_POST_TEXT_MENU_CLICKED_EVENT = "EditPostTextMenuClicked";
export const EDIT_POST_MEDIA_MENU_CLICKED_EVENT = "EditPostMediaMenuClicked";
export const EDIT_POST_MUSIC_MENU_CLICKED_EVENT = "EditPostMusicMenuClicked";
export const EDIT_POST_BRAND_MENU_CLICKED_EVENT = "EditPostBrandMenuClicked";
export const EDIT_POST_COLORS_MENU_CLICKED_EVENT = "EditPostColorsMenuClicked";
export const EDIT_POST_FONTS_MENU_CLICKED_EVENT = "EditPostFontsMenuClicked";
export const EDIT_POST_CURATED_MEDIA_SET_CLICKED_EVENT = "EditPostCuratedMediaSetClicked";
export const EDIT_POST_MEDIA_ADD_MEDIA_CLICKED_EVENT = "EditPostMediaAddMediaClicked";
export const EDIT_POST_MEDIA_REMOVE_CLICKED_EVENT = "EditPostMediaRemoveClicked";
export const EDIT_POST_MEDIA_UPLOAD_CLICKED_EVENT = "EditPostMediaUploadClicked";
export const EDIT_POST_MEDIA_WEBSITE_CLICKED_EVENT = "EditPostMediaWebsiteClicked";
export const EDIT_POST_MEDIA_STOCK_CLICKED_EVENT = "EditPostMediaStockClicked";
export const EDIT_POST_MEDIA_AI_GENERATE_CLICKED_EVENT = "EditPostMediaAIGenerateClicked";
export const EDIT_POST_MEDIA_AI_GENERATE_IMAGE_CREATED_EVENT = "EditPostMediaAiGenerateImageCreated";
export const EDIT_POST_MEDIA_REPLACED_EVENT = "EditPostMediaReplaced";
export const EDIT_POST_MEDIA_UPLOAD_STARTED_EVENT = "EditPostMediaUploadStarted";
export const EDIT_POST_MEDIA_UPLOADED_EVENT = "EditPostMediaUploaded";
export const EDIT_POST_MEDIA_UPLOAD_FAILED_EVENT = "EditPostMediaUploadFailed";
export const EDIT_POST_MEDIA_ADDED_EVENT = "EditPostMediaAdded";
export const EDIT_POST_MUSIC_CHANGED_EVENT = "EditPostMusicChanged";
export const EDIT_POST_MUSIC_APPLIED_EVENT = "EditPostMusicApplied";
export const EDIT_POST_MUSIC_CANCELED_EVENT = "EditPostMusicCanceled";
export const EDIT_POST_TEXT_MODIFIED_EVENT = "EditPostTextModified";

export const EXTERNAL_FONT_LOAD_ERROR_EVENT = "ExternalFontLoadError";

export const MEDIA_LIBRARY_UPLOAD_STARTED_EVENT = "MediaLibraryUploadStarted";
export const MEDIA_LIBRARY_WEBSITE_CLICKED_EVENT = "MediaLibraryWebsiteClicked";
export const MEDIA_LIBRARY_STOCK_CLICKED_EVENT = "MediaLibraryStockClicked";
export const MEDIA_LIBRARY_AI_GENERATE_CLICKED_EVENT = "MediaLibraryAIGenerateClicked";
export const MEDIA_LIBRARY_AI_GENERATE_IMAGE_CREATED_EVENT = "MediaLibraryAiGenerateImageCreated";
export const MEDIA_LIBRARY_UPLOAD_CLICKED = "MediaLibraryUploadClicked";
export const MEDIA_LIBRARY_UPLOADED_EVENT = "MediaLibraryUploaded";
export const MEDIA_LIBRARY_UPLOAD_FAILED_EVENT = "MediaLibraryUploadFailed";
export const MEDIA_LIBRARY_MEDIA_ADDED_EVENT = "MediaLibraryMediaAdded";

export const POST_DRAFT_FEEDBACK_SHOWN_EVENT = "PostDraftFeedbackShown";
export const POST_DRAFT_FEEDBACK_SUBMITTED_EVENT = "PostDraftFeedbackSubmitted";
export const POST_DRAFT_FEEDBACK_SKIPPED_EVENT = "PostDraftFeedbackSkipped";
export const CHAT_MAKE_A_POST_CLICKED_EVENT = "ChatMakeAPostClicked";
export const OWNER_CHOOSE_MEDIA_UI_SHOWN_EVENT = "OwnerChooseMediaUIShown";
export const OWNER_CHOOSE_MEDIA_COMPLETED_EVENT = "OwnerChooseMediaCompleted";
export const OWNER_CHOOSE_MEDIA_SKIPPED_EVENT = "OwnerChooseMediaSkipped";
export const MAKE_A_POST_SEND_EVENT = "MakeAPostSendClicked";
export const MAKE_A_POST_UPLOAD_MEDIA_EVENT = "MakeAPostUploadMedia";

export const SHARE_CHOICE_SHARE_NOW_CLICKED = "ShareChoiceShareNowClicked";
export const SHARE_CHOICE_SCHEDULE_CLICKED = "ShareChoiceScheduleClicked";
export const SHARE_OPTIONS_SHOWN_EVENT = "ShareOptionsShown";
export const SHARE_POST_CLICKED = "SharePostClicked";
export const SHARE_POST_BACK_TO_EDIT_CLICKED = "SharePostBacktoEditClicked";
export const SHARE_POST_SHARE_NOW_CLICKED = "SharePostShareNowClicked";
export const SHARE_POST_COMPLETED_EVENT = "SharePostCompleted";
export const SHARE_POST_FIRST_DOWNLOAD_COMPLETED_EVENT = "SharePostFirstDownloadCompleted";
export const SHARE_POST_DOWNLOAD_FAILED_EVENT = "SharePostDownloadFailed";
export const SHARE_POST_SAVE_COMPLETED_EVENT = "SharePostSaveCompleted";
export const SHARE_POST_SAVE_CANCELED_EVENT = "SharePostSaveCanceled";
export const SHARE_POST_SAVE_FAILED_EVENT = "SharePostSaveFailed";
export const RETRY_BUTTON_CLICKED_EVENT = "RetryButtonClicked";
export const CHAT_MENU_CLICKED_EVENT = "ChatMenuClicked";
export const PLAN_POST_SHARE_CLICKED_EVENT = "PlanPostShareClicked";
export const PLAN_MENU_CLICKED_EVENT = "PlanMenuClicked";
export const MEDIA_LIBRARY_MENU_CLICKED_EVENT = "MediaLibraryMenuClicked";
export const SETTINGS_MENU_CLICKED_EVENT = "SettingsMenuClicked";
export const ASSISTANT_MENU_CLICKED_EVENT = "AssistantMenuClicked";
export const SETTINGS_BUSINESS_NAME_UPDATED_EVENT = "SettingsBusinessNameUpdated";
export const SETTINGS_BUSINESS_DESCRIPTION_UPDATED_EVENT = "SettingsBusinessDescriptionUpdated";
export const SETTINGS_TOPIC_ADDED_EVENT = "SettingsTopicAdded";
export const SETTINGS_TOPIC_REMOVED_EVENT = "SettingsTopicRemoved";
export const SETTINGS_SIGN_OUT_CLICKED_EVENT = "SettingsSignOutClicked";
export const SETTINGS_WEEKLY_PLAN_UPDATED_EVENT = "SettingsWeeklyPlanUpdated";

export const ONBOARDING_QUIZ_STARTED_EVENT = "OnboardingQuizStarted";
export const ONBOARDING_QUIZ_CLOSED_EVENT = "OnboardingQuizClosed";
export const ONBOARDING_QUIZ_BACK_CLICKED_EVENT = "OnboardingQuizBackClicked";
export const ONBOARDING_QUIZ_QUESTION_ONE_COMPLETE_EVENT = "OnboardingQuizQuestionOneComplete";
export const ONBOARDING_QUIZ_QUESTION_TWO_COMPLETE_EVENT = "OnboardingQuizQuestionTwoComplete";
export const ONBOARDING_QUIZ_QUESTION_THREE_COMPLETE_EVENT = "OnboardingQuizQuestionThreeComplete";

export const MEDIA_STYLE_QUIZ_STARTED_EVENT = "MediaStyleQuizStarted";
export const MEDIA_STYLE_QUIZ_RESULTS_SHOWN_EVENT = "MediaStyleQuizResultsShown";
export const MEDIA_STYLE_PREVIEWED_EVENT = "MediaStylePreviewed";
export const MEDIA_STYLE_ENABLED_EVENT = "MediaStyleEnabled";
export const MEDIA_STYLE_DISABLED_EVENT = "MediaStyleDisabled";

export const CHAT_MESSAGE_SENT_EVENT = "ChatMessageSent";
export const ASSISTANT_MESSAGE_RECEIVED_EVENT = "AssistantMessageReceived";
export const QUICK_ACTION_CLICKED_EVENT = "QuickActionClicked";
export const ONBOARDING_QUICK_ACTION_CLICKED_EVENT = "OnboardingQuickActionClicked";
export const PLAN_IDEA_ADD_CLICKED_EVENT = "PlanIdeaAddClicked";
export const PLAN_IDEA_DELETE_CLICKED_EVENT = "PlanIdeaDeleteClicked";
export const PLAN_IDEA_SAVE_CLICKED_EVENT = "PlanIdeaSaveClicked";
export const PLAN_IDEA_EDIT_CLICKED_EVENT = "PlanIdeaEditClicked";
export const PLAN_IDEA_GENERATE_IDEA_CLICKED_EVENT = "PlanIdeaGenerateIdeaClicked";
export const UPSELL_SHOWN_EVENT = "UpsellShown";
export const UPSELL_CANCEL_CLICKED_EVENT = "UpsellCancelClicked";

export const STRIPE_CHECKOUT_STARTED_EVENT = "StripeCheckoutStarted";
export const STRIPE_CHECKOUT_SESSION_CREATED_EVENT = "StripeCheckoutSessionCreated";
export const STRIPE_CHECKOUT_FAILED_EVENT = "StripeCheckoutFailed";
export const STRIPE_CHECKOUT_COMPLETE_EVENT = "StripeCheckoutComplete";
export const PAYMENT_DIALOG_CANCEL_CLICKED_EVENT = "PaymentDialogCancelClicked";
export const PAYMENT_DIALOG_CLOSE_AFTER_COMPLETE_CLICKED_EVENT = "PaymentDialogCloseAfterCompleteClicked";
export const PAYMENT_DIALOG_AUTO_CLOSE_USER_ALREADY_SUBSCRIBED_EVENT = "PaymentDialogAutomaticCloseUserAlreadySubscribed";
export const MANAGE_SUBSCRIPTION_CLICKED_EVENT = "ManageSubscriptionClicked";
export const UPSELL_CONTINUE_TO_PAYMENT_CLICKED_EVENT = "UpsellContinueToPaymentClicked";

export const UPSELL_SOURCE_GENERATE_PLAN_IDEA = "generatePlanIdea";
export const UPSELL_SOURCE_GENERATE_IMAGE = "generateImage";
export const UPSELL_SOURCE_SEND_CHAT_MESSAGE = "sendChatMessage";
export const UPSELL_SOURCE_SHARE_POST = "sharePost";
export const UPSELL_SOURCE_USER_SETTINGS = "userSettings";
export const UPSELL_SOURCE_START_DOWNLOAD = "startDownload";
export const UPSELL_SOURCE_EYEBROW_CLICKED = "eyebrowClicked";

export const ALKAI_APP_VISIBLE_EVENT = "AlkaiAppVisible";

export const ALKAI_APP_HIDDEN_EVENT = "AlkaiAppHidden";
export const APPTIMIZE_PARTICIPATED_EVENT = "ApptimizeParticipated";

export const HEIC_TO_JPEG_CONVERSION_STARTED = "HeicToJpegConversionStarted";
export const HEIC_TO_JPEG_CONVERSION_COMPLETED = "HeicToJpegConversionCompleted";
export const HEIC_TO_JPEG_CONVERSION_FAILED = "HeicToJpegConversionFailed";

export const STYLE_QUIZ_CLICKED = "StyleQuizClicked";
export const SURVEY_LINK_CLICKED = "SurveyLinkClicked";
export const STYLE_AND_BRANDING_CLICKED = "StyleAndBrandingClicked";

export const POST_IDEA_NOTIFICATION_SHOWN = "PostIdeaNotificationShown";
export const POST_IDEA_NOTIFICATION_ERROR = "PostIdeaNotificationError";
export const IN_APP_NOTICE_CLICKED = "InAppNoticeClicked";

export const POST_IDEA_EDIT_BRAND_SETTINGS_CLICKED = "PostIdeaEditBrandSettingsClicked";
export const POST_IDEA_BRAND_SLIDE_TOGGLED = "PostIdeaBrandSlideToggled";
export const POST_IDEA_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED = "PostIdeaBrandKitForBrandSlideToggled";
export const BRAND_SETTINGS_BRAND_SLIDE_TOGGLED = "BrandSettingsBrandSlideToggled";
export const BRAND_SETTINGS_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED = "BrandSettingsBrandKitForBrandSlideToggled";
export const BRAND_SETTINGS_BRAND_KIT_FOR_POST_IDEAS_TOGGLED = "BrandSettingsBrandKitForPostIdeasToggled";
export const BRAND_COLOR_PALETTE_SELECTED = "BrandColorPaletteSelected";
export const BRAND_FONT_SET_SELECTED = "BrandFontSetSelected";
export const BRAND_LOGO_CHANGED = "BrandLogoChanged";

export const KEEP_CURRENT_USER_CLICKED = "KeepCurrentUserClicked";
export const RESTORE_EXISTING_USER_CLICKED = "RestoreExistingUserClicked";
export const EXISTING_USER_RESTORED = "ExistingUserRestored";

export const BRAND_CARD_PREVIEW_CLICKED = "BrandCardPreviewClicked";
export const BRAND_CARD_LAYOUT_SWITCHED = "BrandCardLayoutSwitched";

export const UPLOAD_FONT_COMPLETED = "UploadFontCompleted";
export const UPLOAD_FONT_FAILED = "UploadFontFailed";

export const EDIT_FONT_SOURCE_EDIT_POST = "EditPost";
export const EDIT_FONT_SOURCE_EDIT_BRAND_KIT = "EditBrandKit";

export type EditFontSourceType = typeof EDIT_FONT_SOURCE_EDIT_POST | typeof EDIT_FONT_SOURCE_EDIT_BRAND_KIT;

export const ADD_FONT_SET_CLICKED = "AddFontSetClicked";
export const NEW_FONT_SET_ADDED = "NewFontSetAdded";

export const EDIT_COLOR_PALETTE_SOURCE_EDIT_POST = "EditPost";
export const EDIT_COLOR_PALETTE_SOURCE_EDIT_BRAND_KIT = "EditBrandKit";

export type EditColorPaletteSourceType = typeof EDIT_COLOR_PALETTE_SOURCE_EDIT_POST | typeof EDIT_COLOR_PALETTE_SOURCE_EDIT_BRAND_KIT;

export const ADD_COLOR_PALETTE_CLICKED = "AddColorPaletteClicked";
export const NEW_COLOR_PALETTE_ADDED = "NewColorPaletteAdded";

export const GENERATE_ALL_POSTS_CLICKED = "GenerateAllPostsClicked";
export const GENERATE_POST_CLICKED = "GeneratePostClicked";
export const EXTEND_PLAN_CLICKED = "ExtendPlanClicked";

export const PLAN_WEEK_CHANGED = "PlanWeekChanged";

export const UNSUBSCRIBED_ALL_NOTIFICATIONS_CHANGED = "UnsubscribedAllNotificationsChanged";

export const BUSINESS_SWITCHED = "BusinessSwitched";
export const ADD_BUSINESS_UPSELL_SHOWN = "AddBusinessUpsellShown";
export const STRIPE_UPGRADE_COMPLETE = 'StripeUpgradeComplete';
export const STRIPE_DOWNGRADE_COMPLETE = 'StripeDowngradeComplete';

export const REMOVE_BUSINESS_BUTTON_CLICKED = "RemoveBusinessButtonClicked";
export const DOWNGRADE_BUSINESSES_SHOWN = "DowngradeBusinessesShown";
export const MAKE_OR_RESTORE_BUSINESS_SHOWN = "MakeOrRestoreBusinessShown";
export const MAKE_NEW_BUSINESS_CLICKED = "MakeNewBusinessClicked";
export const RESTORE_BUSINESS_CLICKED = "RestoreBusinessClicked";

export const SUBMIT_A_HELP_TICKET_CLICKED = "SubmitAHelpTicketClicked";

export const FACEBOOK_CUSTOM_EVENTS = [
  INITIAL_RUN_SHOWN_EVENT,
  FIRST_POST_PREVIEW_SHOWN,
  FIRST_POST_PREVIEW_CLICKED,
  SHARE_POST_FIRST_DOWNLOAD_COMPLETED_EVENT
];


