import * as React from "react";
import { freshdeskServices } from "../../services/freshdesk.services";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmail } from "../user/userSlice";
import { RootState } from "../../app/store";
import { Box, TextField, Typography } from "@mui/material";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ALKAI_SUPPORT_EMAIL, ROUTES } from "../constants";
import validator from "validator";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import AlertBanner from "../alert/alertBanner";
import LoadingButton from "@mui/lab/LoadingButton";

export function NewSupportTicket()
{
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const dispatch = useDispatch();
  const initialEmail = useSelector( ( state: RootState ) => getUserEmail( state ) );
  const [email, setEmail] = React.useState( initialEmail || "" );
  const [emailError, setEmailError] = React.useState( "" );
  const [description, setDescription] = React.useState( "" );
  const [descriptionError, setDescriptionError] = React.useState( "" );
  const [subject, setSubject] = React.useState( "" );
  const [subjectError, setSubjectError] = React.useState( "" );
  const [ticketSubmissionInProgress, setTicketSubmissionInProgress] = React.useState( false );

  async function handleSubmit()
  {
    try
    {
      setTicketSubmissionInProgress( true );
      await freshdeskServices.submitTicket( email, description, subject )
      navigateWithSearchParams( ROUTES.CHAT );
    }
    catch (error)
    {
      dispatch( setAlertMessage(
        errorAlert( `We were unable to submit your help request. Please reach out to ${ALKAI_SUPPORT_EMAIL} if this continues.` ) ) );
    }
    finally
    {
      setTicketSubmissionInProgress( false );
    }
  }

  function handleEmailAddressChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setEmail( event.target.value );
  }

  function handleSubjectChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setSubject( event.target.value );
    if ( event.target.value !== "" )
    {
      setSubjectError( "" );
    }
  }

  function handleDescriptionChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    setDescription( event.target.value );
    if ( event.target.value !== "" )
    {
      setDescriptionError( "" );
    }
  }

  async function submitButtonClicked( event: React.MouseEvent<HTMLButtonElement> )
  {
    event.preventDefault();
    if ( email !== "" && subject !== "" && description !== "" )
    {
      if ( validator.isEmail( email ) )
      {
        await handleSubmit()
      }
      else
      {
        setEmailError( "Invalid Email Address" );
      }
    }
    else if ( subject === "" )
    {
      setSubjectError( "Subject is required" );
    }
    else if ( description === "" )
    {
      setDescriptionError( "Description is required" );
    }
    else if ( email === "" )
    {
      setEmailError( "Email is required" );
    }
    else
    {
      dispatch( setAlertMessage( errorAlert( "All fields are required" ) ) );
    }

  }

  return (
    <Box sx={{ p: 10 }}>
      <Typography sx={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 20, mb: 10 }}>Submit a new support ticket</Typography>
      <form>
        <label>
          Email Address:
          <TextField
            className="emailTextField"
            defaultValue={email}
            placeholder="email address required"
            fullWidth={true}
            required={true}
            error={emailError ? true : undefined}
            helperText={emailError}
            onChange={handleEmailAddressChange}/>
        </label>
        <label>
          <Typography>Subject:</Typography>
          <TextField
            className="subjectTextField"
            placeholder="What is the issue?"
            fullWidth={true}
            required={true}
            error={subjectError ? true : undefined}
            helperText={subjectError}
            onChange={handleSubjectChange}/>
        </label>
        <label>
          <Typography>Description:</Typography>
          <TextField
            className="descriptionTextField"
            multiline
            placeholder="Please provide a detailed description of the issue you are experiencing."
            fullWidth={true}
            required={true}
            error={descriptionError ? true : undefined}
            helperText={descriptionError}
            minRows={4}
            onChange={handleDescriptionChange}/>
        </label>
        <LoadingButton variant="contained"
                       color="primary"
                       onClick={!ticketSubmissionInProgress ? submitButtonClicked : undefined}
                       loading={ticketSubmissionInProgress}
                       sx={{ mt: 10 }}>
          Submit Ticket
        </LoadingButton>
      </form>
      <AlertBanner/>
    </Box>
  );
}