import { CuratedMediaSetAPI } from "../../services/curatedMediaSet.services";
import { Box, Stack, Typography } from "@mui/material";

export interface CuratedMediaSetPreviewProps
{
  curatedMediaSet: CuratedMediaSetAPI;
  handleCuratedMediaSetClicked: ( curatedMediaSetSlug: string ) => void;
}

export function CuratedMediaSetPreview( props: CuratedMediaSetPreviewProps )
{
  function handleOnClick()
  {
    props.handleCuratedMediaSetClicked( props.curatedMediaSet.slug )
  }

  return <Stack spacing={1} sx={{ my: 5, cursor: "pointer" }} onClick={handleOnClick}>
    <Box component={"img"} sx={{ width: "90px", height: "137px", objectFit: "cover" }} src={props.curatedMediaSet.cover_image_url}
         alt={props.curatedMediaSet.name}/>
    <Typography>{props.curatedMediaSet.name}</Typography>
  </Stack>
}